import React, {ComponentProps} from 'react';
import s from './ProductColor.scss';
import {ColorSampleGroup} from '@wix/wixstores-client-common-components/dist/src/ColorSampleGroup/ColorSampleGroup';
import {UserInputType} from '../../../constants';
import {
  withTranslations,
  IProvidedTranslationProps,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ErrorTooltipProvider} from '../../ErrorTooltipProvider/ErrorTooltipProvider';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {IColorSampleGroupOption} from '@wix/wixstores-client-common-components/dist/es/src/ColorSampleGroup/ColorSampleGroup';
import {ColorPicker} from 'wix-ui-tpa/ColorPicker';
import {classes as colorOptionStyles} from './ColorOption.st.css';
import cx from 'classnames';

export interface ProductColorsProps {
  optionIndex: number;
}

export enum DataHook {
  ColorOption = 'product-options-color',
  ColorOptionSectionTitle = 'product-colors-title-section',
  ColorOptionTitle = 'product-colors-title',
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductColors extends React.Component<
  ProductColorsProps & ProvidedGlobalProps & IProvidedTranslationProps
> {
  public static defaultProps = {
    allowMultiple: false,
    selected: [],
  };

  /**
   * @deprecated
   */
  private readonly oldHandleChange = ([selected]: IColorSampleGroupOption[]): void => {
    const {
      optionIndex,
      globals: {handleUserInput, validate, product},
    } = this.props;

    const nextSelection = product.options[optionIndex].selections.find(({id}) => id === selected?.id);

    handleUserInput(UserInputType.Selection, nextSelection, optionIndex);

    validate();
  };

  private readonly handleChange: ComponentProps<typeof ColorPicker>['onChange'] = (event) => {
    const {
      optionIndex,
      globals: {handleUserInput, validate, product, userInputs},
    } = this.props;

    const nextSelection = product.options[optionIndex].selections.find(({value}) => value === event.value);

    const isDeselected = userInputs[UserInputType.Selection].some((selection) => selection?.value === event.value);

    handleUserInput(UserInputType.Selection, isDeselected ? undefined : nextSelection, optionIndex);

    validate();
  };

  private get activeSelection() {
    const {
      optionIndex,
      globals: {userInputs},
    } = this.props;

    return userInputs[UserInputType.Selection][optionIndex] ?? undefined;
  }

  private get productOption() {
    const {
      optionIndex,
      globals: {product},
    } = this.props;

    return product.options[optionIndex];
  }

  private get colorPickerOptions(): IColorSampleGroupOption[] | JSX.Element[] {
    const {
      globals: {
        variantInfo,
        experiments: {isUiTpaColorPickerOption, isSelectableOOS},
      },
    } = this.props;

    const getSelectionAvailabilityInfo = (selectionId: number) =>
      variantInfo.selectionsAvailability[this.productOption.id][selectionId];

    if (!isUiTpaColorPickerOption) {
      return this.productOption.selections
        .filter((selection) => getSelectionAvailabilityInfo(selection.id).isVisible)
        .map((selection) => ({
          id: selection.id,
          isDisabled: !getSelectionAvailabilityInfo(selection.id).isSelectable,
          value: selection.value,
          isVisible: true,
          description: selection.description,
        }));
    }

    return this.productOption.selections
      .filter((selection) => getSelectionAvailabilityInfo(selection.id).isVisible)
      .map((selection) => {
        const isDisabled = !getSelectionAvailabilityInfo(selection.id).isSelectable;
        return (
          <ColorPicker.Item
            key={selection.id}
            aria-label={selection.description}
            value={selection.value}
            disabled={isSelectableOOS ? false : isDisabled}
            isCrossedOut={isDisabled}
            tooltip={selection.description}
            checked={(this.activeSelection?.id ? [this.activeSelection.id] : []).includes(selection.id)}
          />
        );
      });
  }

  public readonly renderDescription = (): string => {
    if (!this.activeSelection) {
      return null;
    }

    return `: ${this.activeSelection.description}`;
  };

  private renderColorPicker() {
    const {
      globals: {
        experiments: {isUiTpaColorPickerOption},
      },
    } = this.props;

    if (!isUiTpaColorPickerOption) {
      return (
        <ColorSampleGroup
          colorGroupClassName={s.colorSamplerGroup}
          allowMultiple={false}
          onSelect={this.oldHandleChange}
          options={this.colorPickerOptions as IColorSampleGroupOption[]}
          selected={this.activeSelection ? [this.activeSelection] : []}
          colorSampleClassName={s.colorSampler}
        />
      );
    }

    return (
      <ColorPicker
        className={colorOptionStyles.colorOption}
        onChange={this.handleChange}
        data-hook={DataHook.ColorOption}>
        {this.colorPickerOptions}
      </ColorPicker>
    );
  }

  public render(): JSX.Element {
    const {
      optionIndex,
      globals: {
        errorPlacement,
        isProductSubmitted,
        userInputErrors,
        experiments: {isUiTpaColorPickerOption},
      },
      t,
    } = this.props;

    const selectionIds = this.productOption.selections.map((selection) => selection.id).join('_');
    const titleId = `product-colors-title-${selectionIds}`;
    return (
      <section
        data-hook={DataHook.ColorOptionSectionTitle}
        aria-labelledby={titleId}
        className={cx({[s.uiTpaColoPicker]: isUiTpaColorPickerOption})}>
        <div id={titleId} data-hook={DataHook.ColorOptionTitle} className={s.description}>
          {this.productOption.title}
          {this.renderDescription()}
        </div>
        <ErrorTooltipProvider
          appendTo={'scrollParent'}
          content={t('SELECT_OPTION_WARNING', {optionTitle: this.productOption.title})}
          placement={errorPlacement}
          show={isProductSubmitted && userInputErrors[UserInputType.Selection][optionIndex]}>
          <div className={s.actionable}>{this.renderColorPicker()}</div>
        </ErrorTooltipProvider>
      </section>
    );
  }
}
